import { AmountType } from '../amountType';
import { CreatableObject } from '../rest';

export enum LimitOrderState {
  OPEN = 'OPEN',
  CANCELED = 'CANCELED',
  EXPIRED = 'EXPIRED',
  EXECUTED = 'EXECUTED',
}

export const limitOrderStateLabels: Record<LimitOrderState, string> = {
  [LimitOrderState.OPEN]: 'Open',
  [LimitOrderState.CANCELED]: 'Canceled',
  [LimitOrderState.EXPIRED]: 'Expired',
  [LimitOrderState.EXECUTED]: 'Executed',
};

export const limitOrderStateClasses: Record<LimitOrderState, string> = {
  [LimitOrderState.OPEN]: 'green',
  [LimitOrderState.EXPIRED]: 'red',
  [LimitOrderState.EXECUTED]: 'purple',
  [LimitOrderState.CANCELED]: 'blue',
};

export function limitOrderToHumanReadable(state: LimitOrderState, defaultValue = '-') {
  if (limitOrderStateLabels[state]) {
    return limitOrderStateLabels[state];
  }
  return defaultValue;
}

export interface CreateLimitOrderRequest {
  sellCurrency: string;
  buyCurrency: string;
  sellAmount?: number;
  buyAmount?: number;
  amount: number;
  amountType: AmountType;
  targetClientRate: number;
  expiryDate: string;
}

export interface LimitOrder extends CreatableObject {
  limitOrderId: string;
  sellCurrency: string;
  buyCurrency: string;
  sellAmount: number;
  buyAmount: number;
  targetRate: number;
  expiryDate: string;
  state: LimitOrderState;
}
