<script setup lang="ts">
import { computed, reactive, ref } from 'vue';
import LimitOrderForm from './forms/LimitOrderForm.vue';
import { useAhTradesState } from '..';
import { FormDefinition, FormValidation } from 'ah-common-lib/src/form/interfaces';
import { AmountType, CreateLimitOrderRequest, QuotePriceResponse } from 'ah-api-gateways';
import LimitOrderConfimation from './LimitOrderConfimation.vue';
import { makeFormModel } from 'ah-common-lib/src/form/helpers';
import { checkboxField } from 'ah-common-lib/src/form/models';
import { waitForEntityCreation } from 'ah-requests';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { useToast } from 'ah-common-lib/src/toast';
import { mergeMap } from 'rxjs/operators';

enum LimitOrderSteps {
  LIMIT_CREATOR = 'optionCreator',
  LIMIT_REVIEW = 'optionsReview',
}

const tradeState = useAhTradesState();

const requestManager = useRequestManager().manager;

const toast = useToast();

const stage = ref<LimitOrderSteps>(LimitOrderSteps.LIMIT_CREATOR);

const formValidation = ref<Partial<FormValidation>>();

const tradePrice = ref<QuotePriceResponse>();

const limitOrderCreateRequest = ref<CreateLimitOrderRequest>();

const title = computed(() =>
  stage.value === LimitOrderSteps.LIMIT_CREATOR ? 'Create New Limit Order' : 'Review and Create Limit Order'
);

const createLimitOrderRequest = computed<CreateLimitOrderRequest | null>(() => {
  if (
    tradePrice.value &&
    limitOrderCreateRequest.value?.targetClientRate &&
    limitOrderCreateRequest.value?.expiryDate
  ) {
    return {
      sellCurrency:
        tradePrice.value.ccy1.amountType === AmountType.SELL
          ? tradePrice.value.ccy1.currency
          : tradePrice.value.ccy2.currency,
      buyCurrency:
        tradePrice.value.ccy1.amountType === AmountType.BUY
          ? tradePrice.value.ccy1.currency
          : tradePrice.value.ccy2.currency,
      amount: tradePrice.value.ccy1.isFixedSide
        ? tradePrice.value.ccy1.clientAmount
        : tradePrice.value.ccy2.clientAmount,
      amountType: tradePrice.value.ccy1.isFixedSide
        ? tradePrice.value.ccy1.amountType
        : tradePrice.value.ccy2.amountType,
      targetClientRate: limitOrderCreateRequest.value.targetClientRate,
      expiryDate: limitOrderCreateRequest.value?.expiryDate,
    };
  }
  return null;
});

function createLimitOrder() {
  if (!createLimitOrderRequest.value) {
    throw 'Not enough date for making a limit order request';
  }

  requestManager
    .sameOrCancelAndNew(
      'createTrade',
      tradeState.services.limitOrder
        .createLimitOrder(createLimitOrderRequest.value)
        .pipe(
          mergeMap((idEntity) =>
            waitForEntityCreation(() =>
              tradeState.services.limitOrder.getlimitOrder(idEntity.id, { errors: { silent: true } })
            )
          )
        ),
      createLimitOrderRequest.value
    )
    .subscribe(() => {
      agreementFormDef.form.agreementAgree = false;
      toast.success('Limit order booked successfully!');
      tradePrice.value = undefined;
      limitOrderCreateRequest.value = undefined;
    });
}

function continueFlow() {
  if (stage.value === LimitOrderSteps.LIMIT_CREATOR && tradePrice.value) {
    stage.value = LimitOrderSteps.LIMIT_REVIEW;
  } else {
    createLimitOrder();
  }
}

function goBack() {
  stage.value = LimitOrderSteps.LIMIT_CREATOR;
}

function updateValidation(validation: Partial<FormValidation>) {
  formValidation.value = validation;
}

const isInvalid = computed(
  () =>
    !tradePrice.value ||
    (stage.value === LimitOrderSteps.LIMIT_CREATOR && !!formValidation.value?.$invalid) ||
    (stage.value === LimitOrderSteps.LIMIT_REVIEW && !!agreementFormDef.validation?.$invalid)
);

const agreementFormDef = reactive<FormDefinition>({
  form: makeFormModel({
    name: 'agreementConfirm',
    fieldType: 'form',
    fields: [
      checkboxField(
        'agreementAgree',
        'I confirm that the purpose of this trade is to facilitate a payment for goods and services or direct investment.',
        false,
        {
          required: true,
          errorMessages: {
            mustAccept: 'Must confirm this to continue',
          },
        }
      ),
    ],
  }),
  validation: null,
});
</script>

<template>
  <div class="section">
    <BoxGrid class="title-bar mb-3" align-h="start">
      <h2 class="mb-0">{{ title }}</h2>
      <h3 v-if="stage === LimitOrderSteps.LIMIT_REVIEW" class="mb-1">
        Please review all trade details before confirming
      </h3>
    </BoxGrid>
    <BoxGrid align-h="start">
      <BoxGridItem cols="12" sm="12" :lg="stage === LimitOrderSteps.LIMIT_CREATOR ? 8 : 6" class="p-0">
        <div>
          <TileCard tileTitle="Trade Details">
            <div v-if="stage === LimitOrderSteps.LIMIT_CREATOR || !tradePrice">
              <LimitOrderForm
                @update:validation="updateValidation"
                :tradePrice.sync="tradePrice"
                :limitOrderCreateRequest.sync="limitOrderCreateRequest"
              />
            </div>
            <div v-else-if="stage === LimitOrderSteps.LIMIT_REVIEW">
              <LimitOrderConfimation
                :tradePrice="tradePrice"
                :limitOrderCreateRequest="limitOrderCreateRequest"
                v-if="limitOrderCreateRequest"
              />
            </div>
          </TileCard>
          <ValidatedForm
            v-if="stage === LimitOrderSteps.LIMIT_REVIEW"
            :fm="agreementFormDef.form"
            :validation.sync="agreementFormDef.validation"
            class="px-1 pt-2"
          />
          <div class="my-4" :class="tradeState.mediaQuery.is('smDown') ? 'text-center' : 'text-right '">
            <VButton
              v-if="stage === LimitOrderSteps.LIMIT_REVIEW"
              @click="goBack"
              class="btn-secondary mr-2"
              :disabled="requestManager.anyPending"
              ><IconArrowRight class="back-arrow mr-3" />Go Back
            </VButton>
            <VButton @click="continueFlow" :disabled="isInvalid" :loading="requestManager.anyPending">
              <template v-if="stage === LimitOrderSteps.LIMIT_CREATOR">
                Confirm <IconArrowRight class="ml-3" />
              </template>
              <template v-else> Continue Limit Order <IconArrowRight class="ml-3" /> </template>
            </VButton>
          </div>
        </div>
      </BoxGridItem>
    </BoxGrid>
  </div>
</template>

<style lang="scss" scoped>
.title {
  min-width: 50%;
  font-size: 1.6em;
  @include upToResolution($tabletResolution) {
    font-size: 18px;
    height: 2em;
    line-height: 3.2em;
    margin-bottom: 0 !important;
  }
  @include themedTextColor($color-text, $color-dark-text);
}

.title-bar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  @include upToResolution($tabletResolution) {
    font-size: 15px;
    padding-bottom: 0rem;
    display: block;
    flex-direction: column;
  }

  h2 {
    min-width: 50%;
    font-size: 1.6em;
    @include upToResolution($tabletResolution) {
      font-size: 18px;
      height: 2em;
      line-height: 3.2em;
      margin-bottom: 1rem !important;
    }
  }
}

.back-arrow {
  transform: rotate(180deg);
}
</style>
