<script setup lang="ts">
import {
  batchSetState,
  getChildModel,
  makeFormModel,
  setState,
  toDataModel,
  updateModel,
} from 'ah-common-lib/src/form/helpers';
import { FieldModel, FormDefinition, FormEvent, FormModel, FormValidation } from 'ah-common-lib/src/form/interfaces';
import { financialAmountField, numberField, selectField } from 'ah-common-lib/src/form/models';
import { computed, onBeforeMount, PropType, reactive, ref, watch } from 'vue';
import { useAhTradesState } from '../..';
import { useTradesCurrencyFormatter } from '../../currency/useTradesCurrencyFormatter';
import { compareState, requiredIfStateValue } from 'ah-common-lib/src/form/validators';
import { required } from '@vuelidate/validators';
import {
  AmountType,
  CreateLimitOrderRequest,
  CutoffTimeModel,
  FxOperation,
  getPrimaryCcy,
  HedgingInstruments,
  QuotePriceResponse,
  SpotQuotePriceRequest,
  TimeFrames,
  TradeableDays,
} from 'ah-api-gateways';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { combineLatest } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { of, throwError } from 'rxjs';
import { stripZoneOffset } from 'ah-common-lib/src/helpers/time';
import { addDays, startOfDay, format, parse, addMonths } from 'date-fns';
import { debounce, DebouncedFunc, isEqual, pick } from 'lodash';
import TradePriceExchangeRate from '../info/TradePriceExchangeRate.vue';
import { useToast } from 'ah-common-lib/src/toast';
import { getPhoneNumber } from 'ah-common-lib/src/helpers/calls';
import { ApiError } from 'ah-requests';
import { pricingEngineError, PricingEngineErrorOptions } from './pricingEngineChecks';
import { VCol, VRow } from 'ah-common-lib/src/common/components';

const props = defineProps({
  tradePrice: {
    type: Object as PropType<QuotePriceResponse>,
    required: false,
  },
  limitOrderCreateRequest: {
    type: Object as PropType<CreateLimitOrderRequest>,
    required: false,
  },
});

const emit = defineEmits({
  'update:validation': (_validation: Partial<FormValidation>) => true,
  'update:tradePrice': (_tradePrice?: QuotePriceResponse) => true,
  'update:limitOrderCreateRequest': (_limitOrderCreateRequest: CreateLimitOrderRequest) => true,
});

const tradeState = useAhTradesState();

const toast = useToast();

const requestManager = useRequestManager().manager;

const buyCurrency = ref('EUR');

const sellCurrency = ref('GBP');

const targetDate = ref<string>();

const expiryDate = ref<string>();

const debouncedPrices = ref<DebouncedFunc<() => void>>(
  debounce(() => {
    requestNewPrice();
  }, 800)
);

const debouncedCalculation = ref<DebouncedFunc<(currencyPair: string) => void>>(
  debounce((currencyPair) => {
    calculatePrice(currencyPair);
  }, 800)
);

const rateDirection = ref(AmountType.SELL);

const buyAmountModel = computed(() => getChildModel(amountForm.form, 'buyAmount')! as FieldModel);

const sellAmountModel = computed(() => getChildModel(amountForm.form, 'sellAmount')! as FieldModel);

const amount = computed(() => amountForm.form[inputAmountField.value]);

const inputAmountField = computed(() => (rateDirection.value === AmountType.SELL ? 'sellAmount' : 'buyAmount'));

const calculatedAmountField = computed(() => (rateDirection.value === AmountType.SELL ? 'buyAmount' : 'sellAmount'));

const buyNumberFormatter = useTradesCurrencyFormatter({ currency: buyCurrency });

const sellNumberFormatter = useTradesCurrencyFormatter({ currency: sellCurrency });

const errorMessage = ref<string>();

onBeforeMount(() => {
  if (props.limitOrderCreateRequest) {
    updateModel(amountForm.form, pick(props.limitOrderCreateRequest, ['sellAmount', 'targetClientRate']));
    expiryDate.value = props.limitOrderCreateRequest.expiryDate;
  }
});

const currencyPairForm = reactive<FormModel>(
  makeFormModel({
    name: 'currencyPairForm',
    fieldType: 'form',
    fields: [
      selectField('sellCurrency', 'Selling', [], {
        placeholder: 'Select Currency',
        fieldWrapperClass: 'col col-sm-12 col-md-6 col-lg-2',
        required: true,
      }),
      selectField('buyCurrency', 'Buying', [], {
        placeholder: 'Select Currency',
        fieldWrapperClass: 'col col-sm-12 col-md-6 col-lg-2',
        required: true,
      }),
    ],
  })
);

const amountForm = reactive<FormDefinition>({
  form: makeFormModel({
    name: 'amountForm',
    fieldType: 'form',
    fields: [
      financialAmountField(
        'sellAmount',
        'Sell Amount',
        {
          errorMessages: { required: '' },
          fieldWrapperClass: 'col col-sm-12 col-md-4 col-lg-3',
          numberFormatter: sellNumberFormatter.value,
        },
        {
          required: requiredIfStateValue('sellAmount'),
        }
      ),
      numberField(
        'targetClientRate',
        'Target Rate',
        {
          fieldWrapperClass: 'col col-sm-12 col-md-4 col-lg-3',
          errorMessages: { required: '', minValue: '' },
          defaultValue: null,
        },
        {
          required,
          minValue: compareState(
            'targetClientRate',
            (val) =>
              typeof val !== 'number' ||
              val >
                (props.tradePrice && props.tradePrice.currencyPair === `${sellCurrency.value}${buyCurrency.value}`
                  ? getPrimaryCcy(props.tradePrice).clientRate
                  : 0)
          ),
          maxValue: compareState(
            'targetClientRate',
            (val) =>
              typeof val !== 'number' ||
              val <
                (props.tradePrice && props.tradePrice.currencyPair !== `${sellCurrency.value}${buyCurrency.value}`
                  ? getPrimaryCcy(props.tradePrice).clientRate
                  : Infinity)
          ),
        }
      ),
      financialAmountField('buyAmount', 'Buy Amount', {
        errorMessages: { required: '' },
        fieldWrapperClass: 'col col-sm-12 col-md-4 col-lg-3',
        numberFormatter: buyNumberFormatter.value,
      }),
    ],
  }),
  validation: null,
});

const clientId = computed(() => tradeState.store.useAuthStore().loggedInIdentity?.client?.id);

const isClientUser = computed(() => tradeState.store.useAuthStore().isClientUser);

const currenciesStore = computed(() => {
  if (clientId.value) {
    return tradeState.store.useSettingsStore().getCurrenciesSpot(clientId.value);
  }

  return [];
});

const showRequiredError = computed(() => {
  return (
    amountForm.validation?.$anyDirty &&
    (amountForm.validation.targetClientRate.required.$invalid ||
      amountForm.validation.buyAmount.required.$invalid ||
      amountForm.validation.sellAmount.required.$invalid)
  );
});

const showMinValueError = computed(() => {
  return amountForm.validation?.$anyDirty && amountForm.validation.targetClientRate.minValue.$invalid;
});

const showMaxValueError = computed(() => {
  return amountForm.validation?.$anyDirty && amountForm.validation.targetClientRate.maxValue.$invalid;
});

watch(showRequiredError, () => {
  if (showRequiredError.value) {
    amountForm.validation?.$touch();
  }
});

watch([showMinValueError, showMaxValueError], () => {
  if (showMinValueError.value || showMaxValueError.value) {
    amountForm.validation?.$touch();
    if (amountForm.form[calculatedAmountField.value]) {
      amountForm.form[calculatedAmountField.value] = undefined;
    }
  }
});

watch(
  currenciesStore,
  () => {
    if (currenciesStore.value) {
      setState(
        getChildModel(currencyPairForm, 'sellCurrency')!,
        'options',
        currenciesStore.value.map((i) => ({ label: i.currency, value: i.currency }))
      );
      setState(
        getChildModel(currencyPairForm, 'buyCurrency')!,
        'options',
        currenciesStore.value.map((i) => ({ label: i.currency, value: i.currency }))
      );

      const sellDefaultCurrency = props.limitOrderCreateRequest?.sellCurrency ?? 'GBP';

      if (!currencyPairForm.sellCurrency) {
        currencyPairForm.sellCurrency = currenciesStore.value.some((c) => c.currency === sellDefaultCurrency)
          ? sellDefaultCurrency
          : currenciesStore.value[0].currency;
      }

      const buyDefaultCurrency = props.limitOrderCreateRequest?.buyCurrency ?? 'EUR';
      if (!currencyPairForm.buyCurrency) {
        currencyPairForm.buyCurrency =
          currenciesStore.value.some((c) => c.currency === buyDefaultCurrency) &&
          currencyPairForm.sellCurrency !== buyDefaultCurrency
            ? buyDefaultCurrency
            : currenciesStore.value.filter((c) => c.currency !== currencyPairForm.sellCurrency)[0].currency;
      }
    }
  },
  { immediate: true }
);

watch(
  () => currencyPairForm.buyCurrency,
  (newVal, oldVal) => {
    buyCurrency.value = newVal;
    if (currencyPairForm.buyCurrency === currencyPairForm.sellCurrency) {
      currencyPairForm.sellCurrency = oldVal;
    }
    if (currencyPairForm.buyCurrency) {
      setState(
        getChildModel(currencyPairForm, 'sellCurrency')!,
        'options',
        currenciesStore.value.map((i) => ({ label: i.currency, value: i.currency }))
      );
    }
  },
  { immediate: true }
);

watch(
  () => currencyPairForm.sellCurrency,
  (newVal, oldVal) => {
    sellCurrency.value = newVal;
    if (currencyPairForm.sellCurrency === currencyPairForm.buyCurrency) {
      currencyPairForm.buyCurrency = oldVal;
    }
    if (currencyPairForm.sellCurrency) {
      setState(
        getChildModel(currencyPairForm, 'buyCurrency')!,
        'options',
        currenciesStore.value.map((i) => ({ label: i.currency, value: i.currency }))
      );
    }
  },
  { immediate: true }
);

watch(
  [sellCurrency, buyCurrency],

  requestTradeableDays,
  { immediate: true }
);

watch(
  () => amountForm.validation,
  () => {
    if (amountForm.validation) {
      emit('update:validation', amountForm.validation);
    }
  }
);

watch(buyNumberFormatter, () => setState(buyAmountModel.value, 'numberFormatter', buyNumberFormatter.value));

watch(sellNumberFormatter, () => setState(sellAmountModel.value, 'numberFormatter', sellNumberFormatter.value));

function loadCutoffTime(tradeDirection: string) {
  if (clientId.value && tradeDirection.trim() !== '') {
    return tradeState.services.pricingEngine.getCutoffTime(
      tradeDirection,
      clientId.value,
      FxOperation.FX_SPOT,
      undefined
    );
  }
  return throwError(`Can't load without trade direction`);
}

function requestTradeableDays() {
  expiryDate.value = undefined;
  if (buyCurrency.value && sellCurrency.value) {
    const tradeDirection = `${buyCurrency.value}${sellCurrency.value}`;
    requestManager
      .sameOrCancelAndNew(
        'loadDateConstraints',
        combineLatest([
          tradeState.services.pricingEngine.getTradeableDays(HedgingInstruments.FX_SPOT, tradeDirection),
          loadCutoffTime(tradeDirection),
        ]),
        tradeDirection
      )
      .pipe(
        catchError((error) => {
          if (error.response.status === 400) {
            return of(null);
          } else {
            throw error;
          }
        })
      )
      .subscribe((payload: [TradeableDays, CutoffTimeModel] | null) => {
        if (!payload) return;
        const tradeableDays = payload[0];
        const cutoffDate = payload[1];
        const t0GMT = stripZoneOffset(startOfDay(addDays(new Date(), 2)));

        let tIndex = 0;

        let tDateGMT = stripZoneOffset(parse(tradeableDays.businessDays[tIndex], 'dd-MM-yyyy', new Date()));

        while (
          tDateGMT.valueOf() < t0GMT.valueOf() ||
          tDateGMT.valueOf() < new Date(cutoffDate.firstConversionDate).valueOf()
        ) {
          tIndex++;
          tDateGMT = stripZoneOffset(parse(tradeableDays.businessDays[tIndex], 'dd-MM-yyyy', new Date()));
        }

        targetDate.value = tradeableDays.businessDays[tIndex];

        let expiryDateAux = addMonths(new Date(), 15);

        while (tradeableDays.businessDays.includes(format(expiryDateAux, 'dd-MM-yyyy'))) {
          expiryDateAux = addDays(expiryDateAux, 1);
        }

        expiryDate.value = format(expiryDateAux, 'yyyy-MM-dd');
      });
  }
}

const tradingDeskContactHTMLMessage = computed(() => {
  const tradingDeskEmail = tradeState.theme.tradingDeskEmail;
  const tradingDeskPhoneNumber = tradeState.theme.tradingDeskPhoneNumber;

  if (tradingDeskEmail || tradingDeskPhoneNumber) {
    let out = 'Please contact our trading desk on ';
    if (tradingDeskPhoneNumber) {
      out += getPhoneNumber(tradingDeskPhoneNumber) || tradingDeskPhoneNumber;
      if (tradingDeskEmail) {
        out += ' or ';
      }
    }
    if (tradingDeskEmail) {
      out += `<a target="_blank" href="mailto:${tradingDeskEmail}">${tradingDeskEmail}</a>`;
    }
    out += '.';

    return out;
  }
  return '';
});

function setErrorInField(error: string) {
  batchSetState(amountForm.form, 'errors', {
    [calculatedAmountField.value]: [],
    [inputAmountField.value]: [
      {
        name: error,
        html: true,
        error: '',
      },
    ],
  });

  batchSetState(amountForm.form, 'placeholder', {
    [calculatedAmountField.value]: '',
    [inputAmountField.value]: '',
  });
}

function checkPricingEngineValidation(error: ApiError) {
  const options = {
    sellCurrency: sellCurrency.value,
    buyCurrency: buyCurrency.value,
    tradingDeskContactHTMLMessage: tradingDeskContactHTMLMessage.value,
    isClientUser: isClientUser.value,
  } as PricingEngineErrorOptions;

  const content = pricingEngineError(error, options);

  if (content) {
    errorMessage.value = content;
    setErrorInField('peError');
  }
}

const workingSpotDetails = computed<SpotQuotePriceRequest | null>(() => {
  if (clientId.value && targetDate.value && amount.value) {
    return {
      amountType: rateDirection.value,
      amount: amount.value,
      tradeDirection: `${sellCurrency.value}${buyCurrency.value}`,
      clientId: clientId.value,
      sellCurrency: sellCurrency.value,
      buyCurrency: buyCurrency.value,
      targetDate: targetDate.value
        ? stripZoneOffset(parse(targetDate.value, 'dd-MM-yyyy', new Date())).toISOString()
        : undefined,
      targetTimeFrame: TimeFrames.OTHER,
    };
  }

  return null;
});

function cleanFields() {
  errorMessage.value = undefined;

  batchSetState(amountForm.form, 'errors', [calculatedAmountField.value, inputAmountField.value], []);
  batchSetState(amountForm.form, 'unexpectedError', {
    [calculatedAmountField.value]: false,
    [inputAmountField.value]: false,
  });
  batchSetState(amountForm.form, 'placeholder', {
    [calculatedAmountField.value]: '',
    [inputAmountField.value]: '',
  });
}

function updateFieldPlaceholders() {
  if (!amountForm.validation?.$invalid && workingSpotDetails.value) {
    batchSetState(amountForm.form, 'placeholder', {
      [calculatedAmountField.value]: !!amountForm.form[inputAmountField.value] ? 'Calculating...' : '',
      [inputAmountField.value]: '',
    });
  }
}

function onInputChange() {
  cleanFields();

  batchSetState(amountForm.form, 'required', {
    [calculatedAmountField.value]: !amountForm.form[inputAmountField.value],
    [inputAmountField.value]: true,
  });
  amountForm.form[calculatedAmountField.value] = '';

  updateFieldPlaceholders();
}

watch(
  workingSpotDetails,
  (newVal, oldVal) => {
    if (!isEqual(newVal, oldVal)) {
      if (newVal?.sellCurrency != oldVal?.sellCurrency || newVal?.buyCurrency != oldVal?.buyCurrency) {
        emit('update:tradePrice', undefined);
      }

      onInputChange();

      debouncedPrices.value();
    }
  },
  { immediate: true, deep: true }
);

watch(
  () => [amountForm.form, currencyPairForm, expiryDate.value],
  () => {
    emit('update:limitOrderCreateRequest', {
      ...toDataModel(amountForm.form),
      ...toDataModel(currencyPairForm),
      expiryDate: expiryDate.value,
    });
  },
  { immediate: true, deep: true }
);

function requestNewPrice() {
  if (workingSpotDetails.value) {
    requestManager
      .sameOrCancelAndNew(
        'reloadPrices',
        tradeState.services.pricingEngine.createSpotQuote(workingSpotDetails.value, {
          options: {
            errors: {
              silent: true,
            },
          },
        })
      )
      .subscribe(
        (response) => {
          emit('update:tradePrice', response);

          if (
            (response.currencyPair === `${sellCurrency.value}${buyCurrency.value}` &&
              amountForm.form.targetClientRate > getPrimaryCcy(response).clientRate) ||
            (response.currencyPair !== `${sellCurrency.value}${buyCurrency.value}` &&
              amountForm.form.targetClientRate < getPrimaryCcy(response).clientRate)
          ) {
            onInputChange();
            debouncedCalculation.value(response.currencyPair);
          } else {
            batchSetState(amountForm.form, 'placeholder', [calculatedAmountField.value], '');
          }
        },
        (e) => {
          emit('update:tradePrice', undefined);

          if (amountForm.form[calculatedAmountField.value]) {
            amountForm.form[calculatedAmountField.value] = undefined;
          }

          if (e.response.status == 500) {
            batchSetState(amountForm.form, 'placeholder', [calculatedAmountField.value], '');
            batchSetState(amountForm.form, 'unexpectedError', {
              [calculatedAmountField.value]: true,
              [inputAmountField.value]: false,
            });
            toast.error('An error occured while calculating trade price. Please try again later.');
          }
          checkPricingEngineValidation(e.response.data);
        }
      );
  }
}

function calculatePrice(currencyPair: string) {
  if (amountForm.form.targetClientRate) {
    if (rateDirection.value === AmountType.SELL) {
      if (currencyPair.slice(0, 3) === sellCurrency.value) {
        amountForm.form[calculatedAmountField.value] =
          amountForm.form[inputAmountField.value] * amountForm.form.targetClientRate;
      } else {
        amountForm.form[calculatedAmountField.value] =
          amountForm.form[inputAmountField.value] / amountForm.form.targetClientRate;
      }
    } else {
      if (currencyPair.slice(0, 3) === buyCurrency.value) {
        amountForm.form[calculatedAmountField.value] =
          amountForm.form[inputAmountField.value] * amountForm.form.targetClientRate;
      } else {
        amountForm.form[calculatedAmountField.value] =
          amountForm.form[inputAmountField.value] / amountForm.form.targetClientRate;
      }
    }
  }

  cleanFields();
}

function onFormEvent(event: FormEvent) {
  if (event.event === 'form-field-set-value') {
    if (event.field.$path.includes('sellAmount')) {
      rateDirection.value = AmountType.SELL;
    } else if (event.field.$path.includes('buyAmount')) {
      rateDirection.value = AmountType.BUY;
    } else if (event.field.$path.includes('targetClientRate') && amountForm.validation?.$invalid) {
      amountForm.form[calculatedAmountField.value] = undefined;
    } else if (
      event.field.$path.includes('targetClientRate') &&
      !amountForm.validation?.$invalid &&
      amountForm.form[inputAmountField.value] &&
      props.tradePrice?.currencyPair
    ) {
      onInputChange();
      debouncedCalculation.value(props.tradePrice.currencyPair);
    }
  }
}

const text = computed(() => {
  return `<p class="m-0">You’re selling ${sellCurrency.value} to buy ${buyCurrency.value}.</p>
  However, please note that the Target Rate must follow the standard format of <b>${props.tradePrice?.currencyPair}</b>.`;
});

const tooltipObject = computed(() => {
  return {
    html: true,
    customClass: 'info-tooltip',
    placement: 'bottom',
  };
});
</script>

<template>
  <div>
    <ValidatedForm :fm="currencyPairForm" />

    <DataRow label="Current Rate" md="2" sm="12" class="mb-4">
      <TradePriceExchangeRate :tradePriceResponse="tradePrice" v-if="tradePrice" />
      <LoadingIcon v-else-if="requestManager.requestStates['reloadPrices'] === 'pending'" />
      <span v-else>-</span>
    </DataRow>

    <ValidatedForm :fm="amountForm.form" :validation.sync="amountForm.validation" @form-event="onFormEvent">
      <template #amountForm.targetClientRate:label>
        <label class="field-group-field-label">
          <span>Target Rate</span>

          <span
            v-b-tooltip.hover="tooltipObject"
            :title="text"
            class="search-info-icon"
            v-if="props.tradePrice && props.tradePrice.currencyPair !== `${sellCurrency}${buyCurrency}`"
          >
            <IconInfoCircle class="info-icon" />{{ props.tradePrice.currencyPair }}
          </span>
        </label>
      </template>
    </ValidatedForm>

    <VRow class="field-group" v-if="showRequiredError">
      <VCol class="field-group-errors">
        Must define either the buy or sell amounts and the target rate.<br />Corresponding amount is automatically
        calculated.
      </VCol>
    </VRow>

    <VRow class="field-group" v-if="showMinValueError">
      <VCol class="field-group-errors">
        The client rate must be greater than the current rate
        {{
          `${props.tradePrice ? getPrimaryCcy(props.tradePrice).currency : ''} ${
            props.tradePrice ? getPrimaryCcy(props.tradePrice).clientRate : ''
          }`
        }}.<br />Corresponding amount is automatically calculated.
      </VCol>
    </VRow>

    <VRow class="field-group" v-if="showMaxValueError">
      <VCol class="field-group-errors">
        The client rate must be lower than the current rate
        {{
          `${props.tradePrice ? getPrimaryCcy(props.tradePrice).currency : ''} ${
            props.tradePrice ? getPrimaryCcy(props.tradePrice).clientRate : ''
          }`
        }}.<br />Corresponding amount is automatically calculated.
      </VCol>
    </VRow>

    <div class="field-group mb-4" v-if="errorMessage">
      <div class="col field-group-errors">
        <BoxGrid class="p-0">
          <BoxGridItem cols="1">
            <IconAlertCircle class="icon" />
          </BoxGridItem>
          <BoxGridItem cols="11" class="px-md-0 px-sm-2">
            <div v-html="errorMessage"></div>
          </BoxGridItem>
        </BoxGrid>
      </div>
    </div>

    <BoxGrid class="mt-3">
      <BoxGridItem cols="12">
        <label> Expiry Date </label>
      </BoxGridItem>
      <BoxGridItem cols="12">
        <span class="text-small">Expiry is fifteen months from the Limit Order being placed.</span>
      </BoxGridItem>
    </BoxGrid>
  </div>
</template>

<style lang="scss" scoped>
::v-deep {
  .icon {
    font-size: 1.6em;
    @include themedTextColor($color-danger);
  }

  .field-group-field {
    position: relative;
  }

  .field-group-field-label {
    display: flex;
    justify-content: space-between;
  }
  .search-info-icon {
    @include themedTextColor($x-ui-light-tag-orange-text, $x-ui-dark-tag-orange-text);
    @include themedBackgroundColor($x-ui-light-tag-orange-bg, $x-ui-dark-tag-orange-bg);
    display: flex;
    align-items: center;
    padding: 0px 4px;
    border-radius: 0.25rem;

    .info-icon {
      margin-right: 0.2rem;
      @include themedTextColor($x-ui-light-tag-orange-icon, $x-ui-dark-tag-orange-icon);
    }
  }
  .tooltip-inner {
    @include themedBackgroundColor($x-ui-light-tag-orange-bg, $x-ui-dark-tag-orange-bg);
    @include themedTextColor($x-ui-light-tag-orange-text, $x-ui-dark-tag-orange-text);
  }
}

.bs-tooltip-top ::v-deep .arrow:before {
  @include themedPropColor('border-top-color', $x-ui-light-tag-orange-bg, $x-ui-dark-tag-orange-bg);
}

.bs-tooltip-right ::v-deep .arrow:before {
  @include themedPropColor('border-right-color', $x-ui-light-tag-orange-bg, $x-ui-dark-tag-orange-bg);
}
.bs-tooltip-left ::v-deep .arrow:before {
  @include themedPropColor('border-left-color', $x-ui-light-tag-orange-bg, $x-ui-dark-tag-orange-bg);
}
.bs-tooltip-bottom ::v-deep .arrow:before {
  @include themedPropColor('border-bottom-color', $x-ui-light-tag-orange-bg, $x-ui-dark-tag-orange-bg);
}
</style>
