<script setup lang="ts">
import { findComponentBasePath } from 'ah-common-lib/src/helpers/route';
import { AuthorityType, FeatureFlag } from 'ah-api-gateways';
import { useAuthStore } from '@/app/store/authStore';
import { useIndividualSettingsStore } from '@/app/store/individualSettingsModule';
import { computed, getCurrentInstance, onBeforeMount } from 'vue';
import { RouterLink } from 'vue-router';
import { useFeatureFlag } from 'ah-common-stores';

const props = defineProps({ path: { type: String, required: false, default: '' } });

const individualSettingsStore = useIndividualSettingsStore();

const authStore = useAuthStore();

const isXhedgeProClient = computed(() => individualSettingsStore.client?.enableXHedgePro);

onBeforeMount(() => {
  individualSettingsStore.loadClient();
});

// basePath is set on component setup and not expected to react to prop changes
// eslint-disable-next-line vue/no-setup-props-destructure
let basePath = props.path;
const instance = getCurrentInstance();
if (basePath === '' && instance != null) {
  basePath = findComponentBasePath(instance.proxy);
}

const { isActive: isCreateLimitOrdersActive } = useFeatureFlag({
  featureFlag: FeatureFlag.CREATE_LIMIT_ORDERS_ENABLED,
  force: true,
});
</script>

<template>
  <div class="main" id="trades-sidebar-view">
    <h1 class="px-4">Hedging</h1>
    <h5 class="menu-header">Create New Hedge</h5>
    <ul>
      <RouterLink
        tag="li"
        class="menu-link"
        exact
        :to="`${basePath}/new-trade`"
        v-if="authStore.hasAuthorities(AuthorityType.OPEN_TRADE)"
      >
        Spots and Forwards
      </RouterLink>
      <RouterLink tag="li" class="menu-link" :to="`${basePath}/new-option`" v-if="isXhedgeProClient">
        Options
      </RouterLink>
      <RouterLink tag="li" class="menu-link" :to="`${basePath}/new-limit-order`" v-if="isCreateLimitOrdersActive">
        Limit Orders
      </RouterLink>
    </ul>
    <h5 class="menu-header">Manage Hedges</h5>
    <ul>
      <RouterLink tag="li" class="menu-link" :to="`${basePath}/open-fx`"> Open Spot and Forward Positions </RouterLink>
      <RouterLink tag="li" class="menu-link" :to="`${basePath}/open-options`" v-if="isXhedgeProClient">
        Open Option Positions
      </RouterLink>
    </ul>
    <h5 class="menu-header">Risk Management</h5>
    <ul>
      <RouterLink tag="li" class="menu-link" :to="`${basePath}/limits-collateral`">
        Trading Limits & Collateral
      </RouterLink>
      <RouterLink tag="li" class="menu-link" :to="`${basePath}/credit-mtm`">
        Credit Limits & Mark-to-Market
      </RouterLink>
    </ul>
    <h5 class="menu-header">Reports</h5>
    <ul>
      <RouterLink tag="li" class="menu-link" :to="`${basePath}/all`">All Spots and Forwards </RouterLink>
      <RouterLink tag="li" class="menu-link" :to="`${basePath}/all-options`" v-if="isXhedgeProClient">
        All Options
      </RouterLink>
    </ul>
  </div>
</template>
