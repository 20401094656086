import NavViewGrid from '@/app/components/common/structure/NavViewGrid.vue';
import {
  partnerRoles,
  clientRoles,
  UserRole,
  AuthorityType,
  FeePaymentType,
  ComplianceStatus,
  FeatureFlag,
} from 'ah-api-gateways';
import { ComponentOptions } from 'vue';
import {
  ActivityClientsOBOView,
  ActivityClientsView,
  ActivityOnboardingView,
  ActivityPaymentsView,
  ActivityReportsAndAndStatementsView,
  ActivitySidebarView,
  ActivitySingleClientCreditAndMTMView,
  ActivitySingleClientDashboardView,
  ActivitySingleClientLimitsAndCollateralView,
  ActivitySingleClientView,
  ActivityTradesView,
  ClientDocumentsManagerView,
  ClientInformationView,
  CommissionAnalyticsView,
  CommissionScheduleView,
  OBOMenuView,
  PartnerDashboardView,
  PartnerInformationSettingsView,
  PartnerPaymentsView,
  PartnerSettingsSidebarView,
} from '../views/admin';
import {
  BeneficiariesView,
  ClientPaymentsView,
  DashboardView,
  OutstandingFeesView,
  RegistrationPendentView,
  SingleClientOutstandingFeesView,
  WalletsView,
} from '../views/dashboard';
import {
  AllOptionsTradesView,
  AllTradesView,
  CreditAndMarkToMarketPositionView,
  LimitsAndCollateralView,
  OpenOptionsPositionsView,
  OpenPositionsView,
  OptionView,
  TradeView,
  TradesSidebarView,
  LimitOrderView,
} from '../views/dashboard/trades';
import {
  AccountSettingsView,
  AuthorisationsSettingsView,
  BankAccountsSettingsView,
  CompanyClientInformationSettingsView,
  ConfirmEmailChangeView,
  DevSettingsView,
  FeesListView,
  GroupsSettingsView,
  MembersSettingsView,
  NotificationSettingsView,
  PersonalSettingsView,
  PlaidIntegrationsView,
  RateAlertsSettingsView,
  SettingsSidebarView,
  UbosSettingsView,
} from '../views/settings';
import { CustomRoute, CustomRouteConfig } from './routeModels';
import { Error500View, LoginView, FirstLoginView, SuccessfullyRegisteredView } from '../views/public';
import LimitsProfileWarningBanner from 'ah-trades/src/components/warningBanner/LimitsProfileWarningBanner.vue';
import { useAuthStore } from '../store/authStore';
import { storeSetupDone } from '../store';
import config from '@/config';
import { ACTIVE_MARGIN_CALL_BANNER_META_FLAG } from '../constants/banners';
import SubscriptionsSidebarView from '../views/subscription/SubscriptionsSidebarView.vue';
import PaymentScheduleView from '../views/subscription/PaymentScheduleView.vue';
import { isQuestionnaireFinished } from './guards';
import { featureFlagRouteGuard } from 'ah-common-stores';

const blankComponent = (name = 'BlankRouter'): ComponentOptions<Vue> => ({
  name,
  render(c) {
    return c('router-view');
  },
});

const sessionRoutes: CustomRouteConfig[] = [
  {
    path: 'login',
    component: LoginView,
    props: (route) => ({ redirectTo: route.query.r, redirectToQuery: route.query.q }),
    meta: { unAuthOnly: true },
  },
  {
    path: 'first-login',
    component: FirstLoginView,
    meta: { unAuthOnly: true },
  },
  {
    path: 'resetPassword',
    component: () => import(/* webpackChunkName: "publicViews" */ '../views/public/session/ForgottenPasswordView.vue'),
    meta: { unAuthOnly: true },
  },
  {
    path: 'recoverUsername',
    component: () => import(/* webpackChunkName: "publicViews" */ '../views/public/session/ForgottenUsernameView.vue'),
    meta: { unAuthOnly: true },
  },
  {
    path: 'resetPassword/:token',
    props: true,
    component: () => import(/* webpackChunkName: "publicViews" */ '../views/public/session/ResetPasswordView.vue'),
    meta: { unAuthOnly: true },
  },
  {
    path: 'impersonate',
    component: () => import(/* webpackChunkName: "publicViews" */ '../views/public/session/ImpersonateWaitView.vue'),
    meta: { title: 'Impersonating User' },
  },
  {
    path: 'review',
    component: RegistrationPendentView,
    beforeEnter: (_, __, next) => {
      storeSetupDone.then(() => {
        const authStore = useAuthStore();
        if (!authStore.hasRegistrationSession) {
          return next('/dashboard');
        }
        if (!isQuestionnaireFinished()) {
          return next('/register');
        }
        next();
      });
    },
    meta: { allowedRoles: clientRoles },
  },
  {
    path: 'terms-and-conditions',
    component: () =>
      import(/* webpackChunkName: "publicViews" */ '../views/dashboard/onboarding/ReacceptTermsAndConditionsView.vue'),
    beforeEnter: (_, __, next) => {
      storeSetupDone.then(() => {
        const authStore = useAuthStore();
        authStore.loadComplianceCase({ force: true }).then(() => {
          if (
            ![
              ComplianceStatus.TERMS_AND_CONDITIONS,
              ComplianceStatus.UPDATED_TERMS_AND_CONDITIONS,
              ComplianceStatus.SUSPENDED_UPDATED_TCS_NOT_ACCEPTED,
            ].includes(authStore.complianceStatus as ComplianceStatus) ||
            !authStore.userData?.individual?.owner
          ) {
            return next('/');
          }
          next();
        });
      });
    },
    meta: {
      auth: true,
      allowedRoles: [UserRole.CLIENT_ADMIN],
    },
  },
];

const errorRoutes: CustomRouteConfig[] = [
  {
    path: '/500',
    component: Error500View,
  },
  { path: '*', redirect: '/login' },
];

const dashboard: CustomRouteConfig = {
  path: '/dashboard',
  component: NavViewGrid,
  meta: { auth: true, allowedRoles: clientRoles, redirectDisallowedRole: '/admin' },
  children: [
    {
      path: '',
      component: DashboardView,
      meta: { title: 'Dashboard' },
    },
    {
      path: 'wallets/:walletId?',
      component: WalletsView,
      props: true,
      meta: { title: 'Wallets' },
    },
    {
      path: 'trades',
      components: {
        default: blankComponent('TradesView'),
        sidebar: TradesSidebarView,
        banner: LimitsProfileWarningBanner,
      },
      meta: {
        requiredAuthorities: [AuthorityType.VIEW_TRADES],
      },
      children: [
        {
          path: 'new-trade',
          component: TradeView,
          meta: {
            requiredAuthorities: [AuthorityType.OPEN_TRADE],
            redirectDisallowedRole: './open-fx',
            title: 'Create New Hedge - Spots and Forwards',

            [ACTIVE_MARGIN_CALL_BANNER_META_FLAG]: true,
          },
        },
        {
          path: 'new-option',
          component: OptionView,
          meta: {
            requiredAuthorities: [AuthorityType.OPEN_TRADE],
            validateXHedgeUserPro: true,
            title: 'Create New Hedge - Options',
          },
        },
        {
          path: 'new-limit-order',
          component: LimitOrderView,
          meta: {
            requiredAuthorities: [AuthorityType.OPEN_TRADE],
            title: 'Create Limit Order',
          },
          beforeEnter: featureFlagRouteGuard({
            featureFlag: FeatureFlag.CREATE_LIMIT_ORDERS_ENABLED,
            fallback: '/',
          }),
        },
        {
          path: 'open-fx',
          component: OpenPositionsView,
          meta: {
            title: 'Open Spot and Forward Positions',
          },
        },
        {
          path: 'open-options',
          component: OpenOptionsPositionsView,
          meta: {
            validateXHedgeUserPro: true,
            title: 'Open Option Positions',
          },
        },
        {
          path: 'all/:tradeId?',
          component: AllTradesView,
          props: true,
          meta: {
            title: 'All Spots and Forwards',
          },
        },
        {
          path: 'all-options',
          component: AllOptionsTradesView,
          props: true,
          meta: {
            validateXHedgeUserPro: true,
            title: 'All Options',
          },
        },

        {
          path: 'limits-collateral',
          component: LimitsAndCollateralView,
          meta: {
            title: 'Trading Limits & Collateral',
          },
        },
        {
          path: 'credit-mtm',
          component: CreditAndMarkToMarketPositionView,
          meta: {
            title: 'Credit Limits & Mark-to-Market',
          },
        },
        {
          path: '',
          redirect: './new-trade',
        },
        {
          path: '*',
          redirect: './new-trade',
        },
      ],
    },
    {
      path: 'beneficiaries/:beneficiaryId?',
      component: BeneficiariesView,
      props: true,
      meta: { title: 'Beneficiaries' },
    },
    {
      path: 'payments',
      component: ClientPaymentsView,
      props: true,
      meta: { title: 'Payments' },
    },
    {
      path: 'subscriptions',
      components: {
        default: blankComponent('SubscriptionsView'),
        sidebar: SubscriptionsSidebarView,
      },
      meta: {
        requiredAuthorities: [],
      },
      children: [
        {
          path: 'new-payment-schedule',
          component: PaymentScheduleView,
          meta: {
            requiredAuthorities: [AuthorityType.OPEN_TRADE],
            title: 'Subscription Management - New Payment Schedule',
          },
        },
        {
          path: '',
          redirect: './new-payment-schedule',
        },
        {
          path: '*',
          redirect: './new-payment-schedule',
        },
      ],
    },
    {
      path: '*',
      redirect: './',
    },
  ],
};

const devSettingsRoute: CustomRouteConfig = {
  path: 'dev-settings',
  component: DevSettingsView,
  beforeEnter: (_, __, next) => {
    next(config.showDevTools ? undefined : '/');
  },
  meta: { title: 'Dev Settings', auth: true },
};

const adminSettings: CustomRouteConfig = {
  path: 'settings',
  meta: { redirectDisallowedRole: '/settings' },
  components: {
    default: blankComponent('SettingsView'),
    sidebar: PartnerSettingsSidebarView,
  },
  children: [
    {
      path: 'groups/:groupId?',
      component: GroupsSettingsView,
      props: true,
      meta: { title: 'Groups' },
    },
    {
      path: 'partner-information',
      component: PartnerInformationSettingsView,
      meta: { title: 'Information' },
    },
    {
      path: 'bank-accounts',
      component: BankAccountsSettingsView,
      meta: { title: 'Bank Accounts' },
    },
    {
      path: 'notifications',
      component: NotificationSettingsView,
      meta: { title: 'Notifications' },
    },
    {
      path: 'members/:memberId?',
      component: MembersSettingsView,
      props: true,
      meta: { title: 'Members' },
    },
    devSettingsRoute,
    {
      path: 'account',
      component: AccountSettingsView,
      meta: { title: 'Account Settings' },
    },
    {
      path: 'personal-settings',
      component: PersonalSettingsView,
      meta: { title: 'Personal Info' },
    },
    {
      path: 'payment-fees',
      component: FeesListView,
      props: {
        paymentType: FeePaymentType.PAYMENT,
      },
      meta: { title: 'Payment Fees' },
    },
    {
      path: 'funding-fees',
      component: FeesListView,
      props: {
        paymentType: FeePaymentType.RECEIPT,
      },
      meta: { title: 'Funding Fees' },
    },
    {
      path: '',
      redirect: './partner-information',
    },
    {
      path: '*',
      redirect: './partner-information',
    },
  ],
};

const admin: CustomRouteConfig = {
  path: '/admin',
  component: NavViewGrid,
  meta: { auth: true, allowedRoles: partnerRoles, redirectDisallowedRole: '/dashboard' },
  children: [
    {
      path: '',
      component: PartnerDashboardView,
      meta: { title: 'Dashboard' },
    },
    {
      path: 'wallets/:walletId?',
      component: WalletsView,
      props: true,
      meta: { title: 'Wallets' },
    },
    {
      path: 'payments',
      component: PartnerPaymentsView,
      meta: { title: 'Payments' },
    },
    {
      path: 'beneficiaries/:beneficiaryId?',
      component: BeneficiariesView,
      props: true,
      meta: { title: 'Beneficiaries' },
    },
    {
      path: 'activity',
      components: {
        default: blankComponent('AdminActivityView'),
        sidebar: ActivitySidebarView,
      },
      children: [
        {
          path: 'clients',
          component: ActivityClientsView,
          meta: { title: 'Clients' },
        },
        {
          path: 'trades',
          component: ActivityTradesView,
          meta: { title: 'Trades' },
        },
        {
          path: 'payments',
          component: ActivityPaymentsView,
          meta: { title: 'Payments' },
        },
        {
          path: 'clients/:clientId?',
          component: ActivitySingleClientView,
          props: true,
          meta: { title: 'Client' },
          children: [
            {
              path: '',
              component: ActivitySingleClientDashboardView,
              props: true,
              meta: { rootChild: true },
            },
            {
              path: 'trades/:referenceNumber?',
              component: OpenPositionsView,
              props: true,
              meta: { readonly: true },
            },
            {
              path: 'limits-collateral',
              component: ActivitySingleClientLimitsAndCollateralView,
              props: true,
              meta: { readonly: true },
            },
            {
              path: 'credit-mtm',
              component: ActivitySingleClientCreditAndMTMView,
              props: true,
            },
            {
              path: 'wallets/:walletId?',
              component: WalletsView,
              props: true,
              meta: { readonly: true },
            },
            {
              path: 'beneficiaries/:beneficiaryId?',
              component: BeneficiariesView,
              props: true,
              meta: { readonly: true },
            },
            {
              path: 'client-information',
              component: ClientInformationView,
              props: true,
              meta: { readonly: true },
            },
          ],
        },
        {
          path: 'clients/obo/:clientId?',
          component: ActivityClientsOBOView,
          meta: { title: 'On Behalf of Client' },
          props: true,
          children: [
            {
              path: '',
              component: OBOMenuView,
              props: true,
            },
            {
              path: 'trade',
              component: TradeView,
              props: true,
            },
            {
              path: 'payment/:beneficiaryId?',
              component: BeneficiariesView,
              props: true,
            },
            {
              path: 'limits-collateral',
              component: ActivitySingleClientLimitsAndCollateralView,
            },
            {
              path: 'credit-mtm',
              component: ActivitySingleClientCreditAndMTMView,
            },
            {
              path: 'beneficiary-management/:beneficiaryId?',
              component: BeneficiariesView,
              props: true,
            },
            {
              path: 'trades',
              component: AllTradesView,
              props: true,
            },
            {
              path: 'documents',
              component: ClientDocumentsManagerView,
              props: true,
            },
          ],
        },
        {
          path: 'onboarding',
          component: ActivityOnboardingView,
          meta: {
            fullscreen: true,
            title: 'Onboarding',
          },
        },
        {
          path: 'outstanding-fees',
          component: blankComponent('outstanding-fees'),
          children: [
            {
              path: '',
              component: OutstandingFeesView,
              meta: { title: 'Outstanding Fees' },
            },
            {
              path: ':userType/:clientOrPartnerId',
              component: SingleClientOutstandingFeesView,
            },
          ],
        },
        {
          path: 'reports',
          component: ActivityReportsAndAndStatementsView,
          meta: {
            fullscreen: true,
            title: 'Commission Report',
          },
        },
        {
          path: 'commissions',
          component: CommissionAnalyticsView,
          meta: { title: 'Commission Analytics' },
        },
        {
          path: 'commissions-schedule',
          component: CommissionScheduleView,
          meta: { title: 'Commission Schedule' },
        },
        {
          path: 'open-fx',
          component: OpenPositionsView,
          props: true,
          meta: { title: 'Open Spot and Forward Positions' },
        },
        {
          path: 'open-options',
          component: OpenOptionsPositionsView,
          meta: { title: 'Open Options Positions' },
        },
        {
          path: '',
          redirect: './clients',
        },
        {
          path: '*',
          redirect: './clients',
        },
      ],
    },
    adminSettings,
    {
      path: '*',
      redirect: './',
    },
  ],
};

const settings: CustomRouteConfig = {
  path: '/settings',
  component: NavViewGrid,
  meta: { auth: true, allowedRoles: clientRoles, redirectDisallowedRole: '/admin' },
  children: [
    {
      path: '',
      components: {
        default: blankComponent('SettingsView'),
        sidebar: SettingsSidebarView,
      },
      meta: { redirectDisallowedRole: '/settings' },
      children: [
        {
          path: 'account',
          component: AccountSettingsView,
          meta: { title: 'Account Settings' },
        },
        {
          path: 'personal-settings',
          component: PersonalSettingsView,
          meta: { title: 'Personal Info' },
        },
        {
          path: 'ubos',
          component: UbosSettingsView,
          meta: { title: 'UBOs' },
        },
        {
          path: 'client-information',
          component: CompanyClientInformationSettingsView,
          meta: { allowedRoles: [UserRole.CLIENT_ADMIN], companyOnly: true, title: 'Information' },
        },
        {
          path: 'bank-accounts',
          component: BankAccountsSettingsView,
          meta: { allowedRoles: [UserRole.CLIENT_ADMIN], title: 'Bank Accounts' },
        },
        {
          path: 'rate-alerts',
          component: RateAlertsSettingsView,
          meta: { allowedRoles: [UserRole.CLIENT_ADMIN], title: 'Rate Alerts' },
        },
        {
          path: 'members/:memberId?',
          component: MembersSettingsView,
          meta: { allowedRoles: [UserRole.CLIENT_ADMIN], companyOnly: true, title: 'Members' },
          props: true,
        },
        {
          path: 'groups/:groupId?',
          component: GroupsSettingsView,
          meta: { allowedRoles: [UserRole.CLIENT_ADMIN], title: 'Groups' },
          props: true,
        },
        {
          path: 'notifications',
          component: NotificationSettingsView,
          meta: { allowedRoles: [UserRole.CLIENT_ADMIN], title: 'Notifications' },
        },
        {
          path: 'authorisations',
          component: AuthorisationsSettingsView,
          meta: { title: 'Authorisations' },
        },
        {
          path: 'plaid',
          component: PlaidIntegrationsView,
          meta: { title: 'Plaid Integrations' },
        },
        devSettingsRoute,
        {
          path: '',
          redirect: './personal-settings',
        },
        {
          path: '*',
          redirect: './personal-settings',
        },
      ],
    },
    {
      path: '*',
      redirect: '',
    },
  ],
};

const registerViewConfig: CustomRouteConfig = {
  path: 'register/:clientReferral?',
  component: () => import(/* webpackChunkName: "registrationViews" */ '../views/public/registration/RegisterView.vue'),
  beforeEnter: (_, __, next) => {
    storeSetupDone.then(() => {
      const authModule = useAuthStore();
      if (authModule.hasValidSession) {
        if (!authModule.hasRegistrationSession) {
          return next('/dashboard');
        }
        if (isQuestionnaireFinished()) {
          return next('/review');
        }
      }
      next();
    });
  },
  meta: { unVerifiedOrUnAuth: true },
};

const registrationRoutes: CustomRouteConfig[] = [
  {
    path: '/register/user/:token',
    component: () =>
      import(/* webpackChunkName: "registrationViews" */ '../views/public/registration/InvitedUserRegisterView.vue'),
    props: (route: CustomRoute) => ({ token: route.params.token }),
    meta: { title: 'Register User' },
  },
  registerViewConfig,
  {
    ...registerViewConfig,
    path: 'landing-register',
    props: {
      landingSource: 'LinkedIn',
    },
  },
  {
    ...registerViewConfig,
    path: 'new-register',
    props: {
      landingSource: 'Sales',
    },
  },
  {
    ...registerViewConfig,
    path: 'referral',
    props: (route: CustomRoute) => ({
      source: route.query.source,
      clientId: route.query.clientId,
      individualId: route.query.individualId,
    }),
  },
  {
    path: 'registered-successfully',
    component: SuccessfullyRegisteredView,
    meta: { unAuthOnly: true },
  },
];

const marginCallAgreementRoutes: CustomRouteConfig[] = [
  {
    path: '/margin-call-agreement/:aggrementId',
    component: () =>
      import(/* webpackChunkName: "publicViews" */ '../views/public/marginCallAgreement/MarginCallAgreementView.vue'),
    props: (route: CustomRoute) => ({ agreementId: route.params.aggrementId }),
    meta: { auth: true, title: 'Accept New MCA' },
  },
];

export const routes: CustomRouteConfig[] = [
  dashboard,
  {
    path: '/email-change/:token',
    component: ConfirmEmailChangeView,
    props: true,
    meta: { auth: true },
  },
  settings,
  admin,
  {
    path: '/',
    component: NavViewGrid,
    props: {
      hideSidebar: true,
    },
    children: [...sessionRoutes, ...registrationRoutes, ...marginCallAgreementRoutes, ...errorRoutes],
  },
];
