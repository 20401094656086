import {
  CreateLimitOrderRequest,
  DocumentExport,
  ExportListType,
  LimitOrder,
  ListQuery,
  PaginatedQuery,
  PaginatedResponse,
  standardiseQuerySort,
  VersionedObject,
} from '../models';
import { HttpBasedService, HttpOptions, HttpService } from 'ah-requests';

export class LimitOrderService extends HttpBasedService {
  constructor(http: HttpService, private baseUrl: string) {
    super(http, {
      options: {
        errors: { messageDefaults: { group: 'limitOrderService' } },
      },
    });
  }

  public createLimitOrder(data: CreateLimitOrderRequest) {
    return this.post<VersionedObject>(`${this.baseUrl}limit-orders`, data);
  }

  public getlimitOrder(limitOrderId: string, options?: Partial<HttpOptions<LimitOrder>>) {
    return this.get<LimitOrder>(`${this.baseUrl}limit-orders/${limitOrderId}`, {
      options,
    });
  }

  public listLimitOrders(params: PaginatedQuery, options?: Partial<HttpOptions<PaginatedResponse<LimitOrder>>>) {
    params = standardiseQuerySort(params);
    return this.get<PaginatedResponse<LimitOrder>>(`${this.baseUrl}limit-orders`, {
      ...options,
      axiosConfig: { params },
    });
  }

  public downloadLimitOrdersList(query: ListQuery, fileFormat: ExportListType, documentTitle = 'Trade List') {
    query = standardiseQuerySort(query);
    return this.downloadList(`${this.baseUrl}limit-orders/export`, query, fileFormat, documentTitle);
  }

  private downloadList(
    url: string,
    query: ListQuery,
    fileFormat: ExportListType,
    documentTitle: string,
    fileName?: string
  ) {
    return this.get<DocumentExport>(url, {
      axiosConfig: {
        params: {
          ...query,
          fileFormat,
          documentTitle,
          fileName,
        },
      },
    });
  }
}
