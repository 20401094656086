<script lang="ts" setup>
import { CreateLimitOrderRequest, getPrimaryCcy, getSecondaryCcy, QuotePriceResponse } from 'ah-api-gateways';
import { computed, PropType } from 'vue';
import TradesFormattedCurrency from '../currency/TradesFormattedCurrency.vue';
import { useAhTradesState } from '..';
import { formatInTimeZone } from 'date-fns-tz';

const props = defineProps({
  tradePrice: {
    type: Object as PropType<QuotePriceResponse>,
    required: true,
  },
  limitOrderCreateRequest: {
    type: Object as PropType<CreateLimitOrderRequest>,
    required: true,
  },
});

const tradeState = useAhTradesState();

const primaryRate = computed(() => getPrimaryCcy(props.tradePrice));

const secondaryRate = computed(() => getSecondaryCcy(props.tradePrice));
</script>

<template>
  <div>
    <DataRow label="Currency Pair" cols="5" class="mb-2">{{ tradePrice.currencyPair }} </DataRow>
    <DataRow label="Instrument" cols="5" class="mb-2">FX Limit Order</DataRow>
    <DataRow label="Selling" cols="5" class="mb-2">
      <TradesFormattedCurrency
        class="currency-value"
        :value="limitOrderCreateRequest.sellAmount"
        :currency="limitOrderCreateRequest.sellCurrency"
      />
    </DataRow>
    <DataRow label="Buying" cols="5" class="mb-2">
      <TradesFormattedCurrency
        class="currency-value"
        :value="limitOrderCreateRequest.buyAmount"
        :currency="limitOrderCreateRequest.buyCurrency"
    /></DataRow>
    <DataRow label="Target Rate" cols="5" class="mb-2">
      <div>
        {{ primaryRate.currency }} 1
        <span class="trade-direction"><IconTrade class="exchange-icon" /></span>
        {{ secondaryRate.currency }}
        {{ limitOrderCreateRequest.targetClientRate.toFixed(tradeState.store.useSettingsStore().ratePrecision) }}
        <span class="text-secondary">
          (inverse
          {{
            (1 / limitOrderCreateRequest.targetClientRate).toFixed(tradeState.store.useSettingsStore().ratePrecision)
          }})</span
        >
      </div>
    </DataRow>
    <DataRow label="Expiry Date" cols="5" class="mb-2"
      >{{ formatInTimeZone(limitOrderCreateRequest.expiryDate, 'GMT', 'dd MMM yyyy') }} GMT</DataRow
    >
    <span
      >Once triggered, your limit order will become an FX spot trade to transact the above notional at your target rate.
      You will receive a trade confirmation with settlement details.</span
    >
  </div>
</template>

<style lang="scss" scoped>
.exchange-icon {
  font-size: 10px;
  width: 2em;
  height: 1.5em;
  margin-top: -3px;
}
</style>
